import { myColor } from 'color'
import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import { useDispatch } from 'react-redux'
import { getErrorMessage } from 'helpers/getErrorMessage'
import { getCompanies } from '../../redux/reducers/companyReducer'
import PageLoading from 'widgets/PageLoading.tsx'
import { addAuth } from '../../redux/reducers/authReducer'
import AssetList from './components/AssetList'
import AssetDetail from './components/AssetDetail'
import app from 'axiosConfig'


const Asset = () => {
    const dispatch = useDispatch();
    const [fetchData,setFetchData] = useState(true);
    const [assetList, setAssetList] = useState<any[]>([]);
    const [openDetail,setOpenDetail] = useState(false);
    const [chosenId,setChosenId] = useState(0);

    const fetchCompanies = async () => {
        try {
            const {data} = await app.get("/api/get-companies");
            if(data?.data){
                dispatch(getCompanies(data?.data))
            }
        } catch (error:any) {
            const message = getErrorMessage(error);
            alert(message);
            setFetchData(false);
        }
    }

    const handleChangeCompany = async (id:number) => {
        try {
            setFetchData(true);
            await app.patch("/api/change-company",{companyId:id})
            await fetchAllNecessaryData();
            setAssetList([]);
        } catch (error) {
            const message = getErrorMessage(error);
            alert(message);
            setFetchData(false);
        }
    }

    const handleFetchUserData = async () => {
        try {
            const {data} = await app.get("/api/get-user");
            dispatch(addAuth(data?.data))
        } catch (error) {
            const message = getErrorMessage(error);
            alert(message);
            setFetchData(false);
        }
    }
    
    const fetchAllNecessaryData = async () => {
        try {
            await fetchCompanies();
            await handleFetchUserData();
            setFetchData(false);
        } catch (error) {
            const message = getErrorMessage(error);
            alert(message);
            // setFetchData(false);
        }
    }

    const handelChosenAsset = (id:number)=> {
        setChosenId(id);
        setOpenDetail(true);
    }

    useEffect(()=>{
        fetchAllNecessaryData();
    },[]);

    if(fetchData){
        return <PageLoading/>
    }

  return (
    <div style = {{backgroundColor:myColor.backgroundColor, height:'100vh',overflow:'auto',width:'100vw'}}>
        <Header handleChangeCompany={handleChangeCompany} setAssetList={setAssetList} handelChosenAsset={handelChosenAsset}/>
        <AssetList data={assetList} handelChosenAsset={handelChosenAsset}/>
        {openDetail && <AssetDetail setOpenDetail = {setOpenDetail} id={chosenId}/>}
    </div>
  )
}

export default Asset